<template>
  <div v-if="awardsList.length > 0" class="avatarslider-component">
    <swiper
      :modules="[EffectCoverflow]"
      effect="coverflow"
      :coverflowEffect="{
        rotate: 10,
        stretch: 5,
        depth: 230,
        modifier: 3,
        slideShadows: false,
      }"
      :slides-per-view="'auto'"
      :space-between="0"
      :centeredSlides="true"
      @swiper="setControlledSwiper"
      @slideChange="onSlideChange"
      @click="onSlideClicked"
      class="mySwiper"
    >
      <swiper-slide
        part="swiper-slide"
        v-for="slide in awardsList"
        :key="slide.id"
        class="swiper-slide no-background"
        :class="{ 'card-item': slide.url_image != 'blank' }"
      >
        <div v-if="slide.url_image == 'blank'" class="slide-container"></div>
        <div v-else class="ion-text-center slide-container">
          <div class="slide-img">
            <img
              v-if="slide.url_image"
              :src="slide.url_image"
              @error="handleImgError"
            />
            <img
              v-else
              src="../../public/assets/img/avatar_placeholder.png"
              @error="handleImgError"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {
  IonButton,
  IonModal,
  IonContent,
  IonRow,
  IonCol,
  IonicSwiper,
} from "@ionic/vue";
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
import SwiperCore, { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import { translate } from "../common/helper.auth";

SwiperCore.use([IonicSwiper, EffectCoverflow]);
export default {
  name: "AwardsSlider",
  props: {
    awardsList: Array,
  },
  components: {
    IonButton,
    IonModal,
    IonContent,
    IonRow,
    IonCol,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      EffectCoverflow,
      swiper: null,
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    setControlledSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.$emit("changeCurrentSlide", this.swiper.realIndex);
    },
    onSlideClicked() {
      this.$emit("clickedCurrentSlide");
    },
  },
};
</script>

<template>
  <campaign-layout
    :showBack="computed_debug"
    :showMenu="computed_debug"
    :pageTitle="$t('pageTitle.Mission')"
  >
    <p class="image">
      <img
        v-if="campaignData && campaignData.url_mission_cover"
        :src="campaignData.url_mission_cover"
        @error="handleImgError"
      />
    </p>
    <!-- <div class="text" v-if="campaignData && campaignData.mission_description">
      <p v-for="item in misionLines" :key="item">{{ item }}</p>
    </div> -->

    <div
      v-if="campaignData && campaignData.mission_description"
      class="ion-padding"
    >
      <div
        v-html="
          campaignData.mission_description['it-IT'] ||
          campaignData.mission_description['en-EN']
        "
        class="w-full h-full"
      ></div>
    </div>

    <div
      v-if="campaignData.avatar_personalizzati"
      id="custom-avatar-selection"
      class="ion-padding-bottom ion-margin-bottom"
    >
      <h4>Seleziona un avatar personalizzato per la campagna</h4>
      <avatars-slider
        @changeCurrentSlide="getCurrentAvatar($event)"
        :awardsList="customAvatarsList"
      ></avatars-slider>
      <h4>Dettagli dell'avatar selezionato</h4>
      <ion-card class="ion-padding">
        <p>{{ $root.translate(computed_currentAvatar.title) }}</p>
        <p>
          {{ $root.translate(computed_currentAvatar.nomeCampagna) }}
        </p>
      </ion-card>
    </div>

    <div
      v-if="campaignData.assegnazione_badge"
      id="custom-badge-selector"
      class="ion-margin-top ion-padding-top"
    >
      <h4>Seleziona i tuoi 3 Badge prioritari!</h4>
      <avatars-slider
        @changeCurrentSlide="getCurrentBadge($event)"
        @clickedCurrentSlide="selectCurrentBadge"
        :awardsList="badgesList"
      ></avatars-slider>
      <h4>Dettagli dell'avatar selezionato</h4>
      <ion-card class="ion-padding" v-if="computed_currentBadge">
        <p>
          <strong>{{ $root.translate(computed_currentBadge.title) }}</strong>
        </p>
        <p>Raddoppio punti: {{ computed_currentBadge.raddoppio_punti }}</p>

        <p>
          Perc. Completamento:
          {{ computed_currentBadge.perc_completamento.toFixed(2) }}
        </p>
      </ion-card>

      <h4>Badge selezionati</h4>
      <div class="badges-selezionati">
        <div v-if="selectedBadges.length <= 0">
          Non hai selezionato nessun Badge
        </div>
        <div
          v-else
          class="badges-selezionati-little-icon"
          v-for="element in selectedBadges"
          :key="element.id"
        >
          <img
            @click="selectCurrentBadge(element.id)"
            :src="element.url_image"
            @error="handleImgError"
          />
        </div>
      </div>
    </div>

    <div class="w-full flex justify-content-center">
      <button @click="close" class="px-4 py-3 button-primary">
        {{ $t("mission.close") }}
      </button>
    </div>
  </campaign-layout>
</template>

<script>
import { IonButton, IonCard } from "@ionic/vue";
import { arrowForward } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { translate } from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";

import CampaignService from "../../common/mixins/Campaign";

import AvatarsSlider from "../../components/AvatarsSlider.vue";

export default {
  name: "Mission",
  created() {
    ApiService.get(
      "CampaignManager/GetUserBadges/" + this.$route.params.id
    ).then((res) => {
      this.badgesList.splice(0);
      res.data.forEach((element) => {
        this.badgesList.push(element);
      });
    });
  },
  data() {
    return {
      campaignData: {},
      misionLines: [],

      selectedBadges: [],
      currentBadgeIndex: 0,
      badgesList: [],

      selectedAvatar: null,
      currentAvatarIndex: 0,
      customAvatarsList: [
        {
          id: 0,
          title: {
            "it-IT": "Avatar numero 1  ",
            "en-EN": "Avatar number 1  ",
          },
          nomeCampagna: {
            "it-IT": " Orientato alle vendite ",
            "en-EN": "  ",
          },
        },
        {
          id: 1,
          title: {
            "it-IT": "Avatar numero 2  ",
            "en-EN": "Avatar number 2  ",
          },
          nomeCampagna: {
            "it-IT": "Orientato al funneling ",
            "en-EN": "lorem ipsum  ",
          },
        },
        {
          id: 2,
          title: {
            "it-IT": "Avatar numero 3  ",
            "en-EN": "Avatar number 3  ",
          },
          nomeCampagna: {
            "it-IT": "Orientato alla velocità  ",
            "en-EN": "lorem ipsum  ",
          },
        },
        {
          id: 3,
          title: {
            "it-IT": "Avatar numero 4  ",
            "en-EN": "Avatar number 4  ",
          },
          nomeCampagna: {
            "it-IT": "Orientato al marketing  ",
            "en-EN": "lorem ipsum  ",
          },
        },
        {
          id: 4,
          title: {
            "it-IT": "Avatar numero 5  ",
            "en-EN": "Avatar number 5  ",
          },
          nomeCampagna: {
            "it-IT": "Orientato alla ventita aggressiva  ",
            "en-EN": "lorem ipsum  ",
          },
        },
        {
          id: 5,
          title: {
            "it-IT": "Avatar numero 6  ",
            "en-EN": "Avatar number 6  ",
          },
          nomeCampagna: {
            "it-IT": "Orientato alle relazioni pubbliche  ",
            "en-EN": "lorem ipsum  ",
          },
        },
        {
          id: 6,
          title: {
            "it-IT": "Avatar numero 7  ",
            "en-EN": "Avatar number 7  ",
          },
          nomeCampagna: {
            "it-IT": "Oreintato all'innovazione  ",
            "en-EN": "lorem ipsum  ",
          },
        },
      ],
    };
  },
  components: {
    CampaignLayout,
    AvatarsSlider,
    IonButton,
    IonCard,
  },
  mixins: [CampaignService],
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    getCurrentAvatar(index) {
      this.currentAvatarIndex = index;
    },
    getCurrentBadge(index) {
      this.currentBadgeIndex = index;
    },
    selectCurrentBadge(id) {
      id ? id : (id = this.computed_currentBadge.id);
      let removeIndex = this.selectedBadges.findIndex((item) => item.id === id);

      if (removeIndex >= 0) {
        this.selectedBadges.splice(removeIndex, 1);
      } else {
        if (this.selectedBadges.length < 3) {
          this.selectedBadges.push(this.computed_currentBadge);
        }
      }
    },
    createTextNewLines() {
      this.misionLines.length = 0;

      console.log(
        "CAMPAIGN DATA PRIMA DI MISSION DESCRIPTION",
        this.campaignData
      );

      if (this.campaignData != undefined) {
        translate(this.campaignData.mission_description)
          .split("\n")
          .forEach((element) => {
            this.misionLines.push(element);
          });
      }
    },
    close() {
      this.$router.push(this.getCampaignDefaultUrl(this.campaignData, true));
    },
  },
  computed: {
    computed_currentAvatar() {
      let obj = {};
      this.customAvatarsList.forEach((element) => {
        if (element.id == this.currentAvatarIndex) {
          obj = element;
        }
      });
      return obj;
    },
    computed_currentBadge() {
      return this.badgesList[this.currentBadgeIndex];
    },
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  ionViewWillEnter() {
    const route = this.$route;
    const { id } = route.params;
    this.campaignId = id;
    this.getCampaign(id).then((data) => {
      console.log("this.getCampaign(id).then((data)", data);
      this.campaignData = data;
      // this.createTextNewLines()
    });
  },
};
</script>

<style lang="scss" scoped>
p.image {
  text-align: center;
}
p.text {
  text-align: center;
}
</style>
